<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="`Registration of Site Visit - ${this.cards[0].companyName}`"
        :cards="cards"
        v-slot="{ card }"
      >
        <pro-deck-card
          :title="card.title"
          :actions="getActions(card)"
          expandable
        >
          <div class="row">
            <div class="col-auto">
              <pro-key-value-table :rows="tendererTableRows" :data="card" />
            </div>
          </div>
          <template v-if="card.attendeeList.length !== 0">
            <br />
            <div class="col-auto">{{ getRes("Form.Field.AttendeeList") }}:</div>
            <div class="row">
              <div
                class="col-12"
                v-for="(row, index) in card.attendeeList"
                :key="index"
              >
                <div class="row">
                  <div class="col-auto">
                    <br />
                    {{ getRes("Form.Field.Attendee") }} {{ index + 1 }}:
                    <pro-key-value-table
                      :rows="tendererAttendeeTableRows"
                      :data="row"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <pro-folders
            class="q-ma-md"
            :folders="card.fileList"
            show-download-button
          />
          <q-btn
            no-caps
            icon="PROSmart-Docs"
            :label="getRes('System.Button.View')"
            @click.stop="viewSubmission(card.processId)"
          />
        </pro-deck-card>
      </pro-deck>
      <audit-trail-record-dialog ref="audit"></audit-trail-record-dialog>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProFolders from "../../../../components/PROSmart/ProFolders";
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";
import AuditTrailRecordDialog from "@/components/PROSmart/AuditTrailRecordDialog";

export default {
  components: {
    AuditTrailRecordDialog,
    ProMenuLayout,
    ProDeck,
    ProDeckCard,
    ProFolders,
    ProKeyValueTable,
  },
  data() {
    return {
      cards: [],
      tenderBriefingInfo: {},
      viewFormCode: "",
      editWorkflowProcessCode: "",
      siteVisitConfirmWorkflowInfo: {},
    };
  },
  computed: {
    tendererTableRows() {
      let returnArray = [];
      let list = this.cards[0];
      if (list.preferredDate != null) {
        returnArray.push({
          label: this.getRes("Form.Field.PreferredDate"),
          field: "preferredDate",
        });
      }
      if (list.firstAlternativeDate != null) {
        returnArray.push({
          label: this.getRes("Form.Field.AlternateDate1"),
          field: "firstAlternativeDate",
        });
      }
      if (list.secondAlternativeDate != null) {
        returnArray.push({
          label: this.getRes("Form.Field.AlternateDate2"),
          field: "secondAlternativeDate",
        });
      }
      return returnArray;
    },
    tendererAttendeeTableRows() {
      let returnArray = [];
      let list = this.cards[0].attendeeList[0];
      if (list.attendeeName != null) {
        returnArray.push({
          label: this.getRes("Form.Field.AttendeeName"),
          field: "attendeeName",
        });
      }
      if (list.position != null) {
        returnArray.push({
          label: this.getRes("Form.Field.PositionTitle"),
          field: "position",
        });
      }
      if (list.contactNumber != null) {
        returnArray.push({
          label: this.getRes("Form.Field.ContactNo"),
          field: "contactNumber",
        });
      }
      if (list.email != null) {
        returnArray.push({
          label: this.getRes("Form.Field.EmailAdd"),
          field: "email",
        });
      }
      if (list.personalId != null) {
        returnArray.push({
          label: this.getRes("Form.Field.HKIDPassportNo"),
          field: "personalId",
        });
      }

      return returnArray;
    },
  },
  methods: {
    viewSubmission(processId) {
      this.$refs.audit.showInfo(processId, this.$route.params.id, true);
    },
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "+0800"));
    },
    clean(obj) {
      for (const x of obj) {
        for (let propName in x) {
          if (x[propName] === null || x[propName] === undefined) {
            delete x[propName];
          }
        }
      }
      return obj;
    },
    getActions(card) {
      let actions = [];

      if (
        this.siteVisitConfirmWorkflowInfo.visible &&
        this.siteVisitConfirmWorkflowInfo.allowCreate
      ) {
        actions.push({
          label: "Form.Field.SSVC",
          icon: "PROSmart-Send",
          key: "Send Site Visit Confirmation",
          to: {
            name: "DocumentViewUserForm",
            params: {
              mode: "Edit",
              code: this.siteVisitConfirmWorkflowInfo.workflowCode,
              userId: card.tendererHcmUserId,
            },
          },
        });
      }

      return actions;
    },
  },
  async created() {
    const getArr = (files) => {
      return Array.from(files, (object) => {
        return Object.keys(object).reduce(
          (obj, key) => {
            obj[key.toLowerCase()] = object[key];
            return obj;
          },
          {
            folderDescription: "",
            fileId: object.FileId,
          }
        );
      });
    };
    this.$proSmart.documentUi
      .getTenderBriefingRegistrationByProcurer(
        this,
        this.$route.params.id,
        this.$route.params.tendererId
      )
      .then((info) => {
        info.splice(1);
        this.cards = info.map((submission) => ({
          key: submission.id,
          title: this.$proSmart.common.format.dateTime(
            new Date(submission.submissionDate)
          ),
          tendererId: submission.tendererId,
          tendererHcmUserId: submission.tendererHcmUserId,
          companyName: submission.companyName,
          attendeeList: submission.attendeeList,
          submissionDate: submission.submissionDate,
          fileList: getArr(submission.tendererFile.fileList),
          preferredDate:
            submission.preferredDate == null
              ? null
              : this.$proSmart.common.getFormattedDate(
                  submission.preferredDate
                ),
          firstAlternativeDate:
            submission.firstAlternativeDate == null
              ? null
              : this.$proSmart.common.getFormattedDate(
                  submission.firstAlternativeDate
                ),
          secondAlternativeDate:
            submission.secondAlternativeDate == null
              ? null
              : this.$proSmart.common.getFormattedDate(
                  submission.secondAlternativeDate
                ),
          processId: submission.processId,
        }));
      });
    this.$proSmart.tender
      .getWorkflowCode(this, this.$route.params.id, ["site_visit_confirm"])
      .then(([siteVisitConfirm]) => {
        this.siteVisitConfirmWorkflowInfo = siteVisitConfirm;
      });
    this.cards = this.clean(this.cards);
  },
};
</script>
